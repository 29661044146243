<div
    class="resource-search-card selenium-resource-search-card {{ gtmPrefix }}"
    [attr.data-gtmlabel]="learningObject.title"
    [attr.data-gtmresultindex]="gtmDataCardNumber"
>
    <app-language-badge
        *ngIf="learningObject.languages.length > 1 || learningObject.languages.includes('Spanish')"
        [languages]="learningObject.languages"
        class="language-card"
    >
    </app-language-badge>
    <div class="thumbnail">
        <app-link
            [attr.data-gtmlabel]="getAbsoluteUrl()"
            [attr.data-gtmresultindex]="gtmDataCardNumber"
            [appRouterLink]="learningObject.routerLink"
            [queryParams]="{ student: isStudentExperience ? true : null }"
        >
            <app-resource-card-poster
                [posterImage]="learningObject.getPosterImage(180, 110)"
                [duration]="learningObject.duration"
                [gtmPrefix]="gtmPrefix"
                [title]="learningObject.linkTitle"
                [mediaType]="learningObject.mediaType[0]"
                [gtmDataCardNumber]="gtmDataCardNumber"
            >
            </app-resource-card-poster>
        </app-link>
    </div>
    <div class="content selenium-result-content">
        <app-link
            [title]="learningObject.title"
            [appRouterLink]="learningObject.routerLink"
            [class]="getResourceBodyClass()"
            [attr.data-gtmlabel]="learningObject.canonicalUrl"
            [attr.data-gtmresultindex]="gtmDataCardNumber"
            [queryParams]="{ student: isStudentExperience ? true : null }"
        >
            <app-card-title><span [innerHtml]="learningObject.title"></span></app-card-title>
        </app-link>
        <app-card-description
            ><span [innerHtml]="learningObject.description | safeTruncate: 130"></span
        ></app-card-description>
        <app-search-card-meta
            [gradesDisplay]="learningObject.gradesDisplay"
            [contentProject]="learningObject.project"
            [supportMaterialsCount]="learningObject.supportMaterials.length"
            [supportMaterialsLink]="learningObject.routerLink"
            [brand]="learningObject.brand"
            [gtmDataCardNumber]="gtmDataCardNumber"
            [gtmPrefix]="gtmPrefix"
            [resourceCode]="resourceCode"
        >
        </app-search-card-meta>
    </div>
</div>

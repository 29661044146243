import { Language } from "../language.model"
import {
    AudioMedia,
    DocumentMedia,
    GenericInteractiveMedia,
    HTMLFragmentMedia,
    ImageMedia,
    LTIMedia,
    Media,
    VideoMedia,
} from "./media"
import { MuxDataModel } from "../muxData.model"
import { URSService } from "../../services/urs.service"
import Utils from "../../utils/utils"

export interface AssetResponse {
    guid: string
    title: string
    media_type: string
    medias: Media[]
    description?: string
    use_rights?: string
    accessibility_indicators?: string[]
    content_flags?: ContentFlags[]
    error?: any
    open_mode: AssetOpenMode
}

export class Asset {
    /*
    Business constraint: All medias in the asset are of the same type which also matches self.mediaType.
    Has state.
    It contains the currently selected media.
    Each media is specific to a language.
     */
    public selectedMedia: Media

    constructor(
        public guid: string = "",
        public title: string = "",
        public mediaType: string = "",
        public medias: Media[] = [],
        public description: string = "",
        public permittedUse: string = "",
        public accessibility: string[] = [],
        public contentFlags: ContentFlags[] = [],
        public error: any = null,
        public openMode: string = ""
    ) {
        if (this.medias.length) {
            this.selectedMedia = this.medias[0]
        }
    }

    public static fromData(data: AssetResponse, ursService: URSService): Asset {
        if (!data || !ursService) return

        const medias = (data.medias || []).map((media) => Media.fromData(media, ursService))
        const {
            guid,
            title,
            media_type: mediaType,
            description,
            use_rights: permittedUse,
            accessibility_indicators: accessibility,
            content_flags = [],
            error = null,
            open_mode,
        } = data

        const contentFlags: ContentFlags[] = [...content_flags]

        return new Asset(
            guid,
            title,
            mediaType,
            medias,
            description,
            permittedUse,
            accessibility,
            contentFlags,
            error,
            open_mode
        )
    }

    public selectLanguage(language: Language = null) {
        if (!language) {
            this.selectedMedia = this.medias[0]
        } else {
            this.selectedMedia = this.medias.find((media: Media) => media.language === language)
        }
    }

    public getSelectedMediaLanguage(): Language {
        if (this.selectedMedia) {
            return this.selectedMedia.language
        } else {
            return null
        }
    }

    public getRemainingMedias(): Media[] {
        return this.medias.filter((media) => media.language !== this.selectedMedia.language)
    }

    public getDefaultPosterImage(): string {
        return this.selectedMedia.getDefaultPosterImage()
    }

    public isDocument(): boolean {
        return this.selectedMedia instanceof DocumentMedia
    }

    public isVideo(): boolean {
        return this.selectedMedia instanceof VideoMedia
    }

    public isAudio(): boolean {
        return this.selectedMedia instanceof AudioMedia
    }

    public isImage(): boolean {
        return this.selectedMedia instanceof ImageMedia
    }

    // Interactive Lesson = LTI = ILP
    public isLTI(): boolean {
        return this.selectedMedia instanceof LTIMedia
    }

    public hasLTI(): boolean {
        return !!this.medias.find((media) => media instanceof LTIMedia)
    }

    public isGenericInteractive(): boolean {
        return this.selectedMedia instanceof GenericInteractiveMedia
    }

    public isHTMLFragment(): boolean {
        return this.selectedMedia instanceof HTMLFragmentMedia
    }

    public getMuxData(): MuxDataModel {
        if (this.isVideo()) {
            return new MuxDataModel(this.title, this.getSelectedMediaLanguage(), this.mediaType)
        }
        return null
    }

    public getGTMVideoJSInfo() {
        if (this.isVideo()) {
            return {
                title: this.title,
                guid: "",
                type: this.mediaType,
            }
        }
        return null
    }
}

export class ContentFlags {
    public name: string = ""
    public description: string = ""
    public throughout: boolean = false
    public firstTimeCodeStart: string | null = ""
    public firstTimeCodeEnd: string | null = ""
    public secondTimeCodeStart: string | null = ""
    public secondTimeCodeEnd: string | null = ""
    public thirdTimeCodeStart: string | null = ""
    public thirdTimeCodeEnd: string | null = ""

    constructor(contentFlagResponse?) {
        this.name = contentFlagResponse.name
        this.description = contentFlagResponse.description
        this.throughout = contentFlagResponse.throughout
        this.firstTimeCodeStart = contentFlagResponse.timecode_1_start
        this.firstTimeCodeEnd = contentFlagResponse.timecode_1_end
        this.secondTimeCodeStart = contentFlagResponse.timecode_2_start
        this.secondTimeCodeEnd = contentFlagResponse.timecode_2_end
        this.thirdTimeCodeStart = contentFlagResponse.timecode_3_start
        this.thirdTimeCodeEnd = contentFlagResponse.timecode3_end
    }
}

export enum AssetOpenMode {
    IFRAME = "embedded",
    WINDOWED = "window",
}

<div class="grades-selector-wrapper d-inline position-relative gtm-change-grade-gate">
    <button
        appClickOutside
        class="selenium-navigation-grades-selector grades-selector-btn"
        [ngClass]="{
            'grade-gate': isStudentExperience,
            'opened-grade-modal': isStudentExperience && isGradesPanelOpened,
            'closed-grade-modal': !(isStudentExperience && isGradesPanelOpened),
            'grades-dropdown-modal': isGradesPanelOpened
        }"
        [ngStyle]="{
            'background-color': isGradesPanelOpened ? '#0a145a' : 'transparent'
        }"
        (click)="toggleGradesPanel()"
        (clickOutside)="handleOutsideClick()"
    >
        Grades{{
            !!studentGateService.selectedGradeValue
                ? ": " + studentGateService.selectedGradeValue
                : ""
        }}
        <span
            [@rotateRight]="isGradesPanelOpened"
            [class]="isGradesPanelOpened ? 'arrow-up' : 'arrow-down'"
        ></span>
    </button>
    <div [@fadeInXY]="isGradesPanelOpened">
        <ng-container *ngIf="isGradesPanelOpened && !isStudentExperience; else studentexperience">
            <div class="grades-panel">
                <button
                    class="gtm-navigation-grades-selector grade-button"
                    *ngFor="let gradeBand of grades"
                    [attr.data-gtmlabel]="gradeBand.gtmLabel"
                    (click)="onGradeSelected(gradeBand)"
                >
                    {{ gradeBand.label }}
                </button>
            </div>
        </ng-container>
    </div>
    <ng-template #studentexperience class="student-gate-modal">
        <div class="grades-selector-wrapper position-relative d-block" *ngIf="isGradesPanelOpened">
            <div class="grades-content d-flex justify-content-center position-absolute">
                <app-grade-gate-modal
                    class="d-flex flex-column align-content-center align-items-center justify-content-center p-5 gtmsection-student-gate"
                    (closeModal)="toggleGradesPanel()"
                >
                </app-grade-gate-modal>
            </div>
        </div>
    </ng-template>
</div>

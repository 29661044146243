import { Component, OnInit, ViewChild } from "@angular/core"
import { LocalizationService } from "../../services/localization.service"
import { EduComponent } from "../../utils/eduComponent"
import { LocalizationModalComponent } from "../modals/localization-modal/localization-modal.component"
import { ModalService } from "../modal/modal.service"
import { CookiesService } from "../../services/cookies.service"
import { Cookie } from "../../models/cookie.model"
import { NgbDropdown } from "@ng-bootstrap/ng-bootstrap"
import { Localize } from "../../models/localize.model"
import { fadeInXY } from "../../utils/animations"

@Component({
    selector: "app-localization-confirm",
    templateUrl: "./localization-confirm.component.html",
    styleUrls: ["./localization-confirm.component.scss"],
    animations: [fadeInXY],
})
export class LocalizationConfirmComponent extends EduComponent implements OnInit {
    public localizeData
    public organizationName: string
    private autolocalizationCookie: Cookie
    private readonly COOKIE: string = "localization_not_confirmed"

    @ViewChild("localizationConfirmDropdown", { static: true }) popup: NgbDropdown

    constructor(
        public localizationService: LocalizationService,
        public modalService: ModalService,
        private cookieService: CookiesService
    ) {
        super()
    }

    ngOnInit() {
        this.addSub(
            this.localizationService.localizeData.subscribe((data: Localize) => {
                this.localizeData = data
                this.organizationName = this.localizeData ? this.localizeData.organizationName : ""
                this.autolocalizationCookie = this.cookieService.getCookie(this.COOKIE)
            })
        )
    }

    public openLocalizationChangeModal() {
        this.close()
        this.modalService.open(LocalizationModalComponent, {
            size: "lg",
            windowClass: "change-station-modal",
        })
        this.deleteCookie()
    }

    public showPopup() {
        return !!this.autolocalizationCookie
    }

    public close() {
        this.deleteCookie()
        this.popup.close()
    }

    private deleteCookie() {
        this.cookieService.deleteCookie(this.COOKIE)
    }
}

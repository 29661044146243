import { Component, Input, OnDestroy, OnInit } from "@angular/core"
import { LearningObject } from "../../models/learningObject.model"
import { CHNode } from "../../models/node.model"
import { ErrorStatus, ResourceType, STATIC_IMAGES } from "../../models/constants"
import { AddOnViewService } from "../../services/add-on-view.service"
import { IWindowResizeEvent, WindowSizeService } from "../../services/window-size.service"
import { takeUntil, tap } from "rxjs/operators"
import { Observable, Subject } from "rxjs"

@Component({
    selector: "app-not-found-error",
    templateUrl: "./not-found-error.component.html",
    styleUrls: ["./not-found-error.component.scss"],
})
export class NotFoundErrorComponent implements OnInit, OnDestroy {
    private destroyed$: Subject<void> = new Subject()
    @Input() subjects: CHNode[]
    @Input() learningObjects: LearningObject[] = []
    @Input() expirationDate: string
    @Input() customErrorMessage: string

    @Input() errorStatus: string
    @Input() resourceType: string
    image: string
    isAddOnView: boolean
    isResourceExpired: boolean = false
    innerWidth: number
    resourceTypeEnum = ResourceType
    errorStatusEnum = ErrorStatus

    windowResizeObservable: Observable<IWindowResizeEvent> = this.windowSizeService.resize.pipe(
        takeUntil(this.destroyed$),
        tap((event: IWindowResizeEvent) => (this.innerWidth = event.innerWidth))
    )

    constructor(
        private addOnViewService: AddOnViewService,
        private windowSizeService: WindowSizeService
    ) {
        this.image = STATIC_IMAGES.teacher_student_error_page
    }

    ngOnInit() {
        this.isResourceExpired = this.isResourcePagExpired
        this.isAddOnView = this.addOnViewService.isAddonView
        this.windowResizeObservable.subscribe()
    }

    get isResourcePagExpired() {
        return !!this.expirationDate && new Date() >= new Date(this.expirationDate)
    }

    get title() {
        if (this.isResourceExpired) return "This resource has expired (404)"
        if (this.resourceType === ResourceType.COLLECTION)
            return "This collection is not available (404)"
        if (this.resourceType === ResourceType.RESOURCE)
            return "This resource is not available (404)"

        return "404"
    }

    ngOnDestroy() {
        this.destroyed$.next()
        this.destroyed$.complete()
    }
}

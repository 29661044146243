import { Component, Input, OnInit } from "@angular/core"
import { UserService } from "../../../services/user.service"
import { CardComponent } from "../card.component"
import { routeAnimations, routeTransition } from "../../../utils/animations"

@Component({
    selector: "app-resource-search-card",
    templateUrl: "./resource-search-card.component.html",
    styleUrls: ["./resource-search-card.component.scss"],
    host: { "[@routeTransition]": "true", "[@routeAnimations]": "false", style: "display: block;" },
    animations: [routeTransition, routeAnimations],
})
export class ResourceSearchCardComponent extends CardComponent implements OnInit {
    @Input() gtmDataCardNumber: number
    @Input() resourceCode: string
    isStudentExperience: boolean = false

    constructor(public userService: UserService) {
        super(userService)
    }

    ngOnInit() {
        this.isStudentExperience = this.userService.hasStudentExperience()
    }
}

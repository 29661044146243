import { Component } from "@angular/core"
import { Title } from "@angular/platform-browser"

import { UserService } from "../services/user.service"
import { GTMService } from "../services/gtm.service"
import { routeAnimations, routeTransition } from "../utils/animations"

@Component({
    templateUrl: "./homepage.component.html",
    styleUrls: ["./homepage.component.scss"],
    host: { "[@routeTransition]": "true", "[@routeAnimations]": "false", style: "display: block;" },
    animations: [routeTransition, routeAnimations],
})
export class HomepageComponent {
    constructor(
        private userService: UserService,
        private titleService: Title,
        private gtmService: GTMService
    ) {
        // Landing on homepage resets user experience
        this.userService.setStudentExperience(false)
        this.titleService.setTitle(
            "PBS LearningMedia | Teaching Resources For Students And Teachers"
        )
        this.gtmService.resetDataLayer()
        this.gtmService.pushPageView()
    }
}

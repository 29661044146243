<article class="d-flex flex-column">
    <app-unauthorized-error
        *ngIf="errorCode === ErrorCode.Error401 || errorCode === ErrorCode.Error403"
        [customErrorMessage]="customErrorMessage"
        [errorCode]="errorCode"
        [learningObjects]="learningObjects"
        [nextURL]="nextURL"
        [showContent]="errorCode === ErrorCode.Error403"
        [subjects]="subjects"
        class="d-flex justify-content-center pt-8 pb-4"
    ></app-unauthorized-error>
    <app-not-found-error
        *ngIf="errorCode === ErrorCode.Error404"
        [learningObjects]="learningObjects"
        [subjects]="subjects"
        [expirationDate]="expirationDate"
        [customErrorMessage]="customErrorMessage"
        [errorStatus]="errorStatus"
        [resourceType]="resourceType"
        class="d-flex justify-content-center pt-8 pb-4"
    ></app-not-found-error>
    <app-internal-server-error
        *ngIf="errorCode === ErrorCode.Error500"
        [subjects]="subjects"
        [customErrorMessage]="customErrorMessage"
        class="d-flex justify-content-center pt-8 pb-4"
    ></app-internal-server-error>
</article>

import {
    animate,
    animation,
    keyframes,
    query,
    style,
    transition,
    trigger,
} from "@angular/animations"

const DEFAULT_FADE_TIMING = "0.20"

const fade = transition("* => *", [
    query(":enter", [style({ opacity: 0, visibility: "visible" })], { optional: true }),
    query(":leave", [animate("0.30s", style({ opacity: 0, visibility: "hidden" }))], {
        optional: true,
    }),
    query(":enter", [animate("0.35s", style({ opacity: 1, visibility: "visible" }))], {
        optional: true,
    }),
])

export function fadeInY(a, b, fromOpacity = 0, toOpacity = 1) {
    return animation(
        animate(
            "{{ timing }}s {{ delay }}s",
            keyframes([
                style({
                    opacity: "{{ fromOpacity }}",
                    height: a,
                    offset: 0,
                }),
                style({
                    opacity: "{{ toOpacity }}",
                    height: b,
                    offset: 1,
                }),
            ])
        ),
        {
            params: {
                timing: DEFAULT_FADE_TIMING,
                delay: 0,
                a,
                b,
                fromOpacity,
                toOpacity,
            },
        }
    )
}

export function rotateInDirection(origin, degrees) {
    return animation(
        animate(
            "{{ timing }}s {{ delay }}s",
            keyframes([
                style({
                    "transform-origin": "{{ origin }}",
                    transform: "rotate3d(0, 0, 1, {{ degrees }})",
                    offset: 0,
                }),
                style({
                    "transform-origin": "{{ origin }}",
                    transform: "none",
                    offset: 1,
                }),
            ])
        ),
        {
            params: {
                timing: DEFAULT_FADE_TIMING,
                delay: 0,
                origin,
                degrees,
            },
        }
    )
}

export function fadeOutY(a, b) {
    return fadeInY(a, b, 1, 0)
}
export const fadeOutUpAnimation = fadeOutY("*", 0)
export const fadeInDownAnimation = fadeInY(0, "*")
export const rotateRightAnimation = rotateInDirection("center", "-200deg")
export const routeAnimations = trigger("routeAnimations", [fade])

export const routeTransition = trigger("routeTransition", [fade])
export const fadeInXY = trigger("fadeInXY", [
    transition("void => *", [fadeInDownAnimation]),
    transition("* => void", [fadeOutUpAnimation]),
    transition("* => *", [
        query(":enter", [fadeInDownAnimation], { optional: true }),
        query(":leave", [fadeOutUpAnimation], { optional: true }),
    ]),
])

export const rotateRight = trigger("rotateRight", [transition("* => *", [rotateRightAnimation])])
